import Vue from 'vue'
import VueRouter from 'vue-router'
// import lanhu_tushuguanqueren from '../views/lanhu_tushuguanqueren/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: () =>
      import(/* webpackChunkName: "index" */ "@/views/lanhu_tushuguanqueren/index.vue"),
  },
  // {
  //   path: '/lanhu_tushuguanqueren',
  //   name: 'lanhu_tushuguanqueren',
  //   component: lanhu_tushuguanqueren
  // }
]

const router = new VueRouter({
  routes
})

export default router
